import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "17",
  height: "17",
  fill: "none",
  viewBox: "0 0 17 17"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#374151",
      d: "M5.208 14.082c-.29.149-.618-.111-.56-.444l.623-3.547-2.642-2.517c-.247-.236-.119-.666.212-.713l3.673-.522 1.638-3.245a.385.385 0 0 1 .696 0l1.638 3.245 3.673.522c.331.047.46.477.212.713l-2.642 2.517.623 3.547c.058.333-.27.593-.56.444L8.5 12.39z"
    }, null, -1)
  ])))
}
export default { render: render }