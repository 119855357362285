<template>
  <Chip
    class="!bg-[#FFFCF3] !text-[#DB5826] text-xs py-1 px-2 flex justify-between gap-0.5 items-center"
  >
    <div class="pl-[20px] relative">
      <LightningChargeFill class="text-[#FF6A39] left-0 absolute" />
      <span>{{ t('product.card.sale') }}</span>
    </div>
  </Chip>
</template>
<script lang="ts" setup>
import LightningChargeFill from '@/public/icons/lightning-charge-fill.svg?component'
const { t } = useI18n()
</script>
