export const useIntersectionObserver = (
  intersectionWrapperRef: Ref<HTMLDivElement | undefined>
) => {
  const intersectionHappened = ref(false)
  const observer = ref<IntersectionObserver | undefined>(undefined)

  onMounted(() => {
    if (
      !('IntersectionObserver' in window) ||
      !('IntersectionObserverEntry' in window) ||
      !('intersectionRatio' in window.IntersectionObserverEntry.prototype)
    ) {
      intersectionHappened.value = true
      return
    }

    observer.value = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            intersectionHappened.value = true
          }
        })
      },
      {
        rootMargin: '200px',
        threshold: 0.3,
      }
    )

    if (intersectionWrapperRef.value?.$el) {
      observer.value?.observe(intersectionWrapperRef.value.$el)
    }
  })

  const stopObserving = () => {
    if (intersectionWrapperRef.value?.$el) {
      observer.value?.unobserve(intersectionWrapperRef.value.$el)
    }
  }

  onUnmounted(() => {
    stopObserving()
  })

  return { intersectionHappened, stopObserving }
}
