<template>
  <div class="card-image-wrapper">
    <NuxtLazyImg
      v-if="image"
      class="card-image"
      :src="image"
      :alt="alt || title"
      loading="lazy"
    />

    <div class="card-image-badge">
      <component
        :is="getComponentByBadgeVariant(badge.variant)"
        v-for="badge in badges"
        :key="badge.label"
        variant="card"
      >
        {{ badge.label }}
      </component>
    </div>
    <slot> </slot>
  </div>
</template>

<script setup lang="ts">
import { Chip, EventPromoBadge } from '#components'

export interface ProductCardImageBadge {
  variant: 'default' | 'promo'
  label: string
}

withDefaults(
  defineProps<{
    image?: string // image url
    alt?: string
    title?: string // use title as alt if alt is empty
    badges: ProductCardImageBadge[]
  }>(),
  {
    image: '',
    alt: '',
    title: '',
    badges: () => [],
  }
)

const getComponentByBadgeVariant = (variant: string) => {
  switch (variant) {
    case 'promo':
      return EventPromoBadge
    default:
      return Chip
  }
}
</script>

<style lang="postcss" scoped>
.card-image-wrapper {
  @apply relative w-[172px] h-[136px] rounded-lg overflow-hidden bg-gray-200;

  .product-card-wrapper:not(.product-card-wrapper--mobile) & {
    @apply md:w-full md:h-[222px];
  }

  @media (min-width: 0px) and (max-width: 365px) {
    @apply w-[150px];
  }

  /* styles for swiper slide */
  .swiper-slide & {
    @apply w-full h-[180px];
  }
}

.card-image {
  @apply relative block w-full h-full object-cover duration-300 ease-in-out;

  .product-card:hover & {
    @apply scale-105;
  }
}

.card-image-badge {
  @apply absolute top-0 left-0 z-1 flex items-center;
}
</style>
