<template>
  <div ref="productShowcase" class="overflow-x-hidden md:overflow-x-auto">
    <ErrorBoundary>
      <ProductsLoader
        v-show="loading || !isProductsReady"
        :is-horizontal="isSlider"
      />

      <div v-show="!loading && isProductsReady" class="flex flex-col">
        <!-- Begin::Title -->
        <Heading
          v-if="title"
          :h-class="titleHClass || `h${titleLevel}`"
          :level="titleLevel"
          :title="title"
          :use-h-tag="useHTag"
          class="mb-8 mb-md-4 kft-product-showcase__heading kft-heading--left"
        />
        <!-- End::Title -->

        <component
          :is="productShowcaseContainer"
          class="product-showcase-cards-container"
          :class="{
            'product-showcase-cards-container--column': isSingleColumn,
          }"
          :data-test-id="showcaseContainerDataTestId"
        >
          <template
            v-for="(item, i) in products"
            :key="`kft-search-product-card-${item.objectID}`"
          >
            <component
              :is="productCard"
              :class="{
                flex: !isSlider,
                'slider-item': isSlider,
                hidden: i + 1 > showItemsOnDesktop && isCollapsed,
              }"
            >
              <transition name="fade-slide-up">
                <!-- using v-show for animation effect on homepage -->
                <!-- probably can be fully removed with <transition> -->
                <ProductCard
                  v-show="
                    isMobile ||
                    !isCollapsed ||
                    (i < showItemsOnDesktop && isCollapsed)
                  "
                  v-bind="productCardProps(item, i)"
                  :is-slide="isSlider"
                  handle-click
                  @click="fnHandleClickOnCard(item, i)"
                  @click:wishlist="handleClickWishlist(item)"
                />
              </transition>
            </component>

            <ProductCard
              v-if="i === 0 && specialGiftcardEvent"
              v-bind="productGiftcardProps(specialGiftcardEvent)"
              :is-slide="isSlider"
              is-special-giftcard
              @click:wishlist="handleClickWishlist(specialGiftcardEvent)"
            />

            <template v-for="(position, index) in promotionSlotIndices">
              <slot v-if="i === position" :name="`promotion${index + 1}`" />
            </template>
          </template>
        </component>

        <Button
          v-if="products.length > 4 && isDesktopAccordeon"
          class="show-more-button"
          @click="toggleShowMore"
          >{{
            isCollapsed
              ? $t('product.showcase.showMore')
              : $t('product.showcase.showLess')
          }}
        </Button>
      </div>
      <!-- End::Product List -->
    </ErrorBoundary>
  </div>
</template>

<script setup lang="ts">
import { nextTick } from 'process'
import { useI18n } from 'vue-i18n'
import { computed, resolveComponent, ref, onMounted, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { type AlgoliaEventItem } from '~/composables/types/algolia'
import { sendViewItemList } from '~/composables/useGtmEvents'
import { useSendEventToAlgolia } from '~/composables/product/useProduct'
import { useGetFormattedDuration } from '~/composables/useGetFormattedDuration'
import { useScreenSize } from '~/composables/useScreenSize'
import { useWishlistSidebarUiState } from '~/composables/useUiState'
import { useWishlist } from '~/composables/useWishlist'
import { algoliaEventGetters } from '~/utils/algoliaEventGetters'
import { convertLocaleIntoBCP47 } from '~/utils/localeHelpers'
import type { GtmItemListId } from '~/composables/useKftGtm/types'

const router = useRouter()
const route = useRoute()
const { t, locale } = useI18n()

const { isMobile } = useScreenSize()
const {
  addAlgoliaEventItem,
  isAlgoliaEventItemOnWishlist,
  removeAlgoliaEventItem,
} = useWishlist()
const { toggle: toggleWishlistSidebar } = useWishlistSidebarUiState()

const emit = defineEmits(['click:product-card'])

interface Props {
  title?: string
  queryId?: string | string[]
  titleLevel?: number
  titleHClass?: string
  tags?: any[]
  products?: any[]
  imageLoading?: '' | 'lazy' | 'eager'
  promotionSlotIndices?: number[]
  loading?: boolean
  disableSendEventToAlgolia?: boolean
  algoliaInsightsLabel?: string
  gtmItemListId?: string | null
  algoliaInsightsClient?: object | void | null
  giftCardEvent?: boolean
  showItemsOnDesktop?: number
  useHTag?: boolean
  specialGiftcardEvent?: AlgoliaEventItem
  isDesktopAccordeon?: boolean
  isMobileSlider?: boolean
  isDurationVisible?: boolean
  isSingleColumn?: boolean
  showcaseContainerDataTestId?: string
}

const props = withDefaults(defineProps<Props>(), {
  title: '',
  queryId: '',
  titleLevel: 2,
  titleHClass: 'text-h3 font-semibold',
  tags: () => [],
  products: () => [],
  imageLoading: 'lazy',
  promotionSlotIndices: () => [1, 9],
  loading: false,
  disableSendEventToAlgolia: false,
  algoliaInsightsLabel: '',
  gtmItemListId: null,
  algoliaInsightsClient: () => ({}),
  giftCardEvent: false,
  mobileHorizontalScroll: false,
  showItemsOnDesktop: 4, // works together with isDesktopAccordeon
  useHTag: true,
  specialGiftcardEvent: undefined,
  isDesktopAccordeon: false,
  isMobileSlider: false,
  isDurationVisible: true,
  isSingleColumn: false,
  showcaseContainerDataTestId: 'search-results',
})

// Dynamic slider components import
const isSlider = computed(() => props.isMobileSlider && isMobile.value)
const productShowcaseContainer = computed(() =>
  isSlider.value ? resolveComponent('ProductsShowcaseSlider') : 'div'
)
const productCard = computed(() =>
  isSlider.value ? resolveComponent('SwiperSlide') : 'div'
)

const headers = useRequestHeaders(['x-bot'])

// Hide loading until components are mounted
// We want to hide the loader if the page is rendered on the server and a bot is requesting the page
const isProductsReady = ref(
  process.client ? false : headers['x-bot'] === 'true'
)
onMounted(() => {
  nextTick(() => {
    isProductsReady.value = true
  })
})

const getBadges = (item: AlgoliaEventItem) => {
  const badges = []

  const generalBadgeLabel = algoliaEventGetters.getBadgeLabel(item, t)

  if (generalBadgeLabel)
    badges.push({
      label: generalBadgeLabel,
      variant: 'default',
    })

  if (algoliaEventGetters.getHasPromotions(item))
    badges.push({ label: t('product.card.sale'), variant: 'promo' })

  return badges
}

// Product card props in one place
const productCardProps = (item: AlgoliaEventItem, index: number) => {
  return {
    dataInsightsObjectId: item.objectID,
    duration: fnGetFormattedDuration(algoliaEventGetters.fnGetDuration(item)),
    durationTitle: t('product.card.duration'),
    minNumberOfPeople: item.min_number_of_people ?? 0,
    maxNumberOfPeople: item.max_number_of_people ?? 0,
    image: algoliaEventGetters.getPrincipalImage(item),
    imageLoading: props.imageLoading,
    next: null,
    link: algoliaEventGetters.fnGetLink(item),
    productCategory: algoliaEventGetters.fnGetMainCategory(item),
    productType: '',
    badges: getBadges(item),
    rating: algoliaEventGetters.getAverageRating(item, localeBCP47),
    regularPrice: algoliaEventGetters.getRegularPriceAmount(item),
    specialPrice: algoliaEventGetters.getSpecialPriceAmount(item),
    showAddToCartButton: false,
    style: { '--index': index },
    title: item.title || 'simple-event-showcase',
    alt: algoliaEventGetters.getPrincipalAlt(item),
    wishlistIcon: true,
    isOnWishlist: isAlgoliaEventItemOnWishlist(item),
    hasShippingCosts: algoliaEventGetters.hasShippingCosts(item),
    isDurationVisible: props.isDurationVisible,
    isTeamevent: algoliaEventGetters.getIsTeamevent(item),
    isMobileView: props.isSingleColumn,
    class: {
      'md:hidden': index + 1 > 4 && isCollapsed.value,
    },
  }
}

// Special giftcard props
const productGiftcardProps = (item: AlgoliaEventItem) => {
  return {
    dataTestId: 'product-card-giftcard',
    duration: fnGetFormattedDuration(algoliaEventGetters.fnGetDuration(item)),
    image: algoliaEventGetters.getPrincipalImage(item),
    link: `/giftcard/${algoliaEventGetters.fnGetPermalink(item)}/`,
    productCategory: algoliaEventGetters.fnGetMainCategory(item),
    regularPrice: algoliaEventGetters.getRegularPriceAmount(item),
    specialPrice: algoliaEventGetters.getSpecialPriceAmount(item),
    rating: algoliaEventGetters.getAverageRating(item, localeBCP47),
    title: item.title || 'No title',
    wishlistIcon: true,
    isOnWishlist: isAlgoliaEventItemOnWishlist(item),
    isDurationVisible: false,
    isMobileView: props.isSingleColumn,
  }
}

const fnGetFormattedDuration = (d: any) => useGetFormattedDuration(d)

const shouldRunSendViewItemList = ref(false)
const localeBCP47 = convertLocaleIntoBCP47(locale.value)

const isCollapsed = ref(props.isDesktopAccordeon)

const productShowcase = ref<HTMLElement | null>(null)
const toggleShowMore = () => {
  isCollapsed.value = !isCollapsed.value

  // scroll to top position of container when close show more
  if (isCollapsed.value && productShowcase.value) {
    const header = 116
    const margin = 32
    window.scrollTo({
      top:
        productShowcase.value.getBoundingClientRect().top +
        window.scrollY -
        header -
        margin,
      behavior: 'smooth',
    })
  }
}

// TODO check twice if we really need it
const routerPushToEvent = (object: AlgoliaEventItem, queryId: string) => {
  router.push(
    algoliaEventGetters.getRouterPushToEvent(
      object,
      queryId,
      locale.value,
      route.query.layout === 'sup'
    )
  )
}

const handleClickWishlist = (item: AlgoliaEventItem) => {
  if (isAlgoliaEventItemOnWishlist(item)) {
    removeAlgoliaEventItem(item)
  } else {
    addAlgoliaEventItem(item)
    toggleWishlistSidebar()
  }
}

const fnHandleClickOnCard = (item: AlgoliaEventItem, i: number) => {
  if (!props.disableSendEventToAlgolia) {
    useSendEventToAlgolia({
      objectID: item.objectID,
      position: Number(i + 1),
      algoliaInsightsClient: props.algoliaInsightsClient,
      label: props.algoliaInsightsLabel,
      queryId: Array.isArray(props.queryId)
        ? props.queryId.join(',')
        : props.queryId,
      locale: locale.value,
    })
  }

  emit('click:product-card', item)
  routerPushToEvent(
    item,
    Array.isArray(props.queryId) ? props.queryId.join(',') : props.queryId
  )
  // TODO [GTM] sendSelectListItem(props.gtmItemListId, [item], i);
}

watch(
  () => props.products,
  (newValue) => {
    if (newValue && newValue.length > 0 && props.gtmItemListId) {
      if (process.client) {
        sendViewItemList(props.gtmItemListId as GtmItemListId, props.products)
      } else {
        shouldRunSendViewItemList.value = true
      }
    }
  },
  { immediate: true }
)
</script>

<style lang="postcss">
.show-more-button {
  @apply w-[300px] h-[45px] self-center mt-4 hidden md:block;
}

.product-showcase-cards-container {
  @apply grid grid-cols-1 gap-2;

  &:not(&--column) {
    @apply md:grid-cols-4 md:gap-4;
  }
}

.slider-item {
  @media (min-width: 0px) and (max-width: 320px) {
    width: 85%;
  }

  width: 70%;
  max-width: 240px;
}

.fade-slide-up-enter-active,
.fade-slide-up-leave-active {
  transition: transform 0.3s ease;
}

.fade-slide-up-enter-from,
.fade-slide-up-leave-to {
  transform: translateY(30px);
  opacity: 0;
}

.fade-slide-up-leave-active {
  position: absolute;
}
</style>
